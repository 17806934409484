import React from 'react'

const ThankSection2 = () => {
  return (
    <section className="section-t-2">
        <div className="container text-center">
            <div className="row">
            <div className="col-md-1" />
            <div className="col-md-10">
                <h2 className="title">
                Book a <span className="textPrimary">free branding</span> intake call
                </h2>
                <p>
                with one of our project expert. They can get on a quick call with you for us to understand your detailed requirements to get the right Virtual Assistant for you.
                </p>
            </div>
            <div className="col-md-1" />
            </div>
        </div>
    </section>

  )
}

export default ThankSection2
