import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <header className="header text-center mt-4">
        <div className="container">
            <Link to='/'>
                <img src="assets/images/header-logo.png" className="img-fluid" alt="klizo solution"/>    
            </Link> 
        </div>
    </header>
  )
}

export default Header
