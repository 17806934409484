import React from 'react'
import PropTypes from 'prop-types'

const Slider2Block = props => {
    const data = props.data;
  return (
    <div className="contentWrapper text-start">
        <div className="mb-2">
            <img
            src="assets/images/rev-logo.png"
            className="img-fluid"
            alt=""
        />
        </div>
        <div className="authorWrpr">
            <div className="name">{data.name}</div>
            <div className="">
                <img
                src="assets/images/5-star.png"
                alt=""
                className="img-fluid"
                />
            </div>
        </div>
        <div className="content">
            <p>
                {data.content}
            </p>
        </div>
    </div>
  )
}

Slider2Block.propTypes = {
    name: PropTypes.string,
    content: PropTypes.string,
}

Slider2Block.defaultProps = {
    name: 'James T',
    content: 'This company is the best! Had my logo and web page up in no time and for half the cost! I would definitely recommend it!'
}

export default Slider2Block
