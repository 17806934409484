import React from 'react'
import { UserContext } from './CreateContext'
import { useContext } from "react";

const ThankSection1 = () => {
  const {formData} = useContext(UserContext)
  return (
    <section className="section-t-1">
        <div className="container text-center border-bottom">
            <div className="row">
            <div className="col-md-1" />
            <div className="col-md-10">
                <img
                src="assets/images/big-tick.png"
                className="img-fluid mb-3"
                alt=""
                />
                <h1 className="title">{formData.first_name + ' ' + formData.last_name}</h1>
                <p>
                Thanks for showing your interest in our Virtual Assistant Services. We will email you a form that will ask you a few more details to understand your exact requirements.
                </p>
            </div>
            <div className="col-md-1" />
            </div>
        </div>
    </section>

  )
}

export default ThankSection1
