
import { useContext } from "react";
import { InlineWidget } from "react-calendly";
import { UserContext } from "./CreateContext";





const Calendly = () => {

  const {formData} = useContext(UserContext)
  
  return (
    <div className="calendly_area">
      <InlineWidget url="https://calendly.com/klizos/logo-intake?hide_event_type_details=1" prefill={{
        email: formData.email || '',
        name: formData.first_name + ' ' + formData.last_name || '',
        customAnswers : {
          a1: formData.phone || ''
        }
      }}
      styles={{
        height: '630px'
      }}
       />
    </div>
  )
}



export default Calendly

