import React from "react";
// import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import Slider1Block from "../blocks/Slider1Block";
// import PropTypes from 'prop-types'

const slides = [
    {
        img: 'assets/images/s1.jpg',
        alt: 'slider image'
    },
    {
        img: 'assets/images/s2.jpg',
        alt: 'slider image'
    },
    {
        img: 'assets/images/s3.jpg',
        alt: 'slider image'
    },
]

const Slider1 = props => {

  return (
    <>
      <Swiper pagination={{clickable: true}}
       modules={[Pagination]} 
       className="mySwiper" 
       spaceBetween={20} 
       loop={true}
       slidesPerView={2}
       breakpoints={{
                576: {
                  slidesPerView: 2,
                  spaceBetween: 40
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 40
                  },
              }}
        >
        {slides.map((slide,i) => 
            <SwiperSlide  key={i}>
                <Slider1Block data={slide}/>
            </SwiperSlide>
        )}
      </Swiper>
    </>
  )
}

Slider1.propTypes = {

}

export default Slider1
