import React from 'react'

const Section4 = () => {
  return (
    <section className="section4">
        <div className="container">
            <h2 className="title">Biggest benefits of hiring a Virtual Assistant</h2>
            <div className="row">
            <div className="col-lg-6 mb-4 mb-lg-0">
                <ul className="matterList">
                <li>
                    <div className="master_icon">
                        <img src="assets/images/m1.png" className="img-fluid" alt="" />
                    </div>
                    The Reduces operating cost
                </li>
                <li>
                    <div className="master_icon">
                        <img src="assets/images/m2.png" className="img-fluid" alt="" />
                    </div>    
                    Save time on initiatives and projects
                </li>
                <li>
                    <div className="master_icon">
                        <img src="assets/images/m3.png" className="img-fluid" alt="" />
                    </div>    
                    Greater work efficiency
                </li>
                <li>
                    <div className="master_icon">
                        <img src="assets/images/m4.png" className="img-fluid" alt="" />
                    </div>    
                    Best way to expand your reach
                </li>
                <li>
                    <div className="master_icon">
                        <img src="assets/images/m5.png" className="img-fluid" alt="" />
                    </div>    
                    You don’t have to pay for benefits
                </li>
                <li>
                    <div className="master_icon">
                        <img src="assets/images/m6.png" className="img-fluid" alt="" />
                    </div>    
                    Less time, higher productivity
                </li>
                <li>
                    <div className="master_icon">
                        <img src="assets/images/m7.png" className="img-fluid" alt="" />
                    </div>    
                    A healthier, more flexible work environment
                </li>
                <li>
                    <div className="master_icon">
                        <img src="assets/images/m8.png" className="img-fluid" alt="" />
                    </div>    
                    Peace of mind
                </li>
                </ul>
            </div>
            <div className="col-lg-6 text-center text-lg-start">
                <img src="assets/images/d1.png" alt="" className="img-fluid" />
            </div>
            <div className="col-md-12 text-center mt-4">                

                <a
                                        href="#get-my-assistant"
                                        className="btn submitBtn"
                                    >
                                        <span className="me-2">
                                        GET MY Assistant
                                        </span>{" "}
                                        <img
                                            src="assets/images/right-arrow.png"
                                            alt=""
                                            className="img-fluid"
                                        />
                                    </a>
            </div>
            </div>
        </div>
    </section>

  )
}

export default Section4
