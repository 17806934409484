import React from 'react'
import PropTypes from 'prop-types'

const Slider1Block = props => {
    const data = props.data;
  return (
    <div className='sliderimg'>
      <img src={data.img} className="w-100" alt={data.alt}/>
    </div>
  )
}

Slider1Block.propTypes = {
    img: PropTypes.string,
    alt: PropTypes.string,
}

Slider1Block.defaultProps = {
    img: 'assets/images/s1.jpg',
    alt: 'slider image'
}

export default Slider1Block
