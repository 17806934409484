import React from 'react'

const Section1 = () => {
  return (
    <section className="section1">
        <div className="container text-center">
            <h1 className="title">Save time, Get more done! Get a Virtual Assistant Today!</h1>
            <p>Starting at $1299, get a dedicated full time resource to help manage tasks</p>
        </div>
    </section>
  )
}

export default Section1
