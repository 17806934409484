import React from 'react'
import Slider1 from '../sliders/Slider1'

const Section3 = () => {
  return (
    <section className="section3">
        <div className="container">
            <Slider1/>
        </div>
    </section>

  )
}

export default Section3
