import React from 'react'
import ThankSection1 from '../components/ThankSection1'
import ThankSection2 from '../components/ThankSection2'
import ThankSection3 from '../components/ThankSection3'



const ThankYou = () => {
  
  return (
    <>
    <ThankSection1/>
    <ThankSection2/>
    <ThankSection3/>
    </>
  )
}



export default ThankYou
