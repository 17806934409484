import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
// import './main.scss';
import './custom-style.scss';
import LandingPage from './Pages/LandingPage';
import Header from './components/Header'
import Footer from './components/Footer'
import ThankYou from './Pages/ThankYou';
import { UserContext } from './components/CreateContext';
import { useState } from 'react';


function App() {

  
  const [formData, setFormData] = useState({});

  return (
    <BrowserRouter>
    <UserContext.Provider value={{formData, setFormData}}>
    <Header/>
    <Routes>
      <Route path="/" element={<LandingPage />}/>
      <Route path="/thankyou" element={<ThankYou />}/>
    </Routes>
    <Footer/> 
    </UserContext.Provider>
  </BrowserRouter>
  );
}

export default App;
