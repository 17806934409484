import { useContext, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { UserContext } from "./CreateContext";
import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from 'react-bootstrap/InputGroup';
import { Formik } from "formik";
import * as Yup from "yup";

const Section2 = () => {
    const navigate = useNavigate();
    const [stillSubmitting, setStillSubmitting] = useState(false);
    const {
        setFormData
    } = useContext(UserContext);

    const [searchParams] =  useSearchParams();

    const formSchema = Yup.object().shape({
        first_name: Yup.string()
            .min(2, "Too Short!")
            .matches(
                /^[aA-zZ\s]+$/,
                "Only alphabets are allowed for this field "
            )
            .required("First Name cannot be empty"),
        last_name: Yup.string()
            .min(2, "Too Short!")
            .matches(
                /^[aA-zZ\s]+$/,
                "Only alphabets are allowed for this field "
            )
            .required("Last Name cannot be empty"),
        email: Yup.string()
            .email("Invalid email")
            .matches(
                /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                "Invalid email format"
            )
            .required("Email cannot be empty"),
        phone: Yup.string()
            .min(10, "Minimum 10 digits required")
            .max(21, "Maximum 15 digits allowed")
            .required("Phone cannot be empty"),
    });

    function phoneFormat(input) {
        input = input.replace(/\D/g, "");
        input = input.substring(0, 15);
        var size = input.length;
        if (size == 0) {
            input = input;
        } else if (size < 4) {
            input = "(" + input;
        } else if (size < 7) {
            input = "(" + input.substring(0, 3) + ") " + input.substring(3, 6);
        } else {
            input =
                "(" +
                input.substring(0, 3) +
                ") " +
                input.substring(3, 6) +
                " - " +
                input.substring(6, 15);
        }
        return input;
    }

    function onFormSubmit(data) {
        const dataFromFrom = {
            first_name: data.first_name,
            last_name: data.last_name,
            email: data.email,
            phone: '+1 '+data.phone.replace(/[-() ]/g, "").replace(" ", ""),
            campaignFrom: "va"
        };

        if( searchParams.get("utm_source") ){
            dataFromFrom['utm_source'] = searchParams.get("utm_source");
        }

        setFormData(data);
        //console.log(dataFromFrom)

        fetch("https://new.dev.klizos.com/api/create-lead", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: "Basic " + btoa("amardeep@klizos.com:password"),
            },
            body: JSON.stringify(dataFromFrom),
        })
        .then((response) => response.json())
        .then((data) => {
            navigate("/thankyou");
        })
        .catch((error) => {
            setStillSubmitting(false);
        });



    }

    return (
        <section className="section2" id="get-my-assistant">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 text-center text-lg-end mb-5 mb-lg-0">
                        <img
                            src="assets/images/form-left.png"
                            className="img-fluid"
                            alt=""
                        />
                    </div>
                    <div className="col-lg-6">
                        <Formik
                            initialValues={{
                                first_name: "",
                                last_name: "",
                                phone: "",
                                email: "",
                            }}
                            validationSchema={formSchema}
                            onSubmit={(values, { setSubmitting }) => {
                                setStillSubmitting(true);
                                setTimeout(() => {
                                    onFormSubmit(values);
                                    //alert(JSON.stringify(values, null, 2));
                                    setSubmitting(false);
                                }, 400);
                            }}
                        >
                            {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting,
                                /* and other goodies */
                            }) => (
                                <Form
                                    className="fomrWrpr ps-4"
                                    noValidate
                                    onSubmit={handleSubmit}
                                >
                                    <h2 className="title">Fill the form:</h2>
                                    <div className="formGroup">
                                        <label className="labelControl">
                                            First Name*
                                        </label>
                                        <Form.Control
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.first_name.trim()}
                                            type="text"
                                            name="first_name"
                                            autoFocus
                                            className="formControl rounded-0"
                                            placeholder="John"
                                            isValid={
                                                touched.first_name &&
                                                !errors.first_name
                                            }
                                            isInvalid={!!errors.first_name}
                                        />

                                        {errors.first_name &&
                                        touched.first_name ? (
                                            <div className="customInvalid">
                                                {errors.first_name}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="formGroup">
                                        <label className="labelControl">
                                            Last Name*
                                        </label>
                                        <Form.Control
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.last_name.trim()}
                                            type="text"
                                            name="last_name"
                                            className="formControl rounded-0"
                                            placeholder="Doe"
                                            isValid={
                                                touched.last_name &&
                                                !errors.last_name
                                            }
                                            isInvalid={!!errors.last_name}
                                        />
                                        {errors.last_name &&
                                        touched.last_name ? (
                                            <div className="customInvalid">
                                                {errors.last_name}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="formGroup">
                                        <label className="labelControl">
                                            Email*
                                        </label>
                                        <Form.Control
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                            type="email"
                                            name="email"
                                            required
                                            className="formControl rounded-0"
                                            placeholder="email@domain.com"
                                            isValid={
                                                touched.email && !errors.email
                                            }
                                            isInvalid={!!errors.email}
                                        />
                                        {errors.email && touched.email ? (
                                            <div className="customInvalid">
                                                {errors.email}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="formGroup">
                                        <label className="labelControl">
                                            Phone*
                                        </label>
                                        <InputGroup>
                                            <InputGroup.Text id="basic-addon1" className=" border-0">+1 </InputGroup.Text>
                                            <Form.Control
                                                type="tel"
                                                name="phone"
                                                required
                                                className={`formControl rounded-0 w-auto ps-2`}
                                                placeholder="Phone"
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={phoneFormat(values.phone)}
                                                isValid={
                                                    touched.phone && !errors.phone
                                                }
                                                isInvalid={!!errors.phone}
                                            />
                                        </InputGroup>
                                        

                                        {errors.phone && touched.phone ? (
                                            <div className="customInvalid">
                                                {errors.phone}
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="formGroup">
                                        <button
                                            type="submit"
                                            className="btn submitBtn"
                                            disabled={
                                                stillSubmitting || isSubmitting
                                            }
                                        >
                                            <span className="me-2 text-uppercase">
                                                {isSubmitting
                                                    ? "Redirecting... "
                                                    : "GET my Assistant"}
                                            </span>{" "}
                                            <img
                                                src="assets/images/right-arrow.png"
                                                alt=""
                                                className="img-fluid"
                                            />
                                        </button>
                                    </div>
                                    <div className="terms">
                                        <p>
                                            <b>Terms:</b> By signing up for this
                                            virtual assistant, you agree to our
                                            terms and privacy policy. We are
                                            committed to providing value to our
                                            clients and keeping their
                                            information private.
                                        </p>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Section2;
