import React from "react";
// import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import Slider2Block from "../blocks/Slider2Block";
// import PropTypes from 'prop-types'

const slide2s = [
    {
        name: 'James T',
        content: 'This company is the best! Had my logo and web page up in no time and for half the cost! I would definitely recommend it!'
    },
    {
        name: 'Jesse Jurado',
        content: 'This is an amazing company that helped get my business to the next level. I recommend them to all my business associates.'
    },
    {
        name: 'Chris Gonzalez',
        content: "One of the best companies I've had the pleasure of working with. From start to finish, Joey ensured that my project was delivered on time and met all of our specifications. I was never in the dark and never had to guess where the project stood. Their attention to detail and communication were outstanding. We will absolutely be working with them again. Very highly recommended!"
    }

]

const Slider2 = props => {

  return (
    <div className="position-relative">
      <Swiper navigation={true}
       modules={[Navigation]} 
       style={{ marginLeft: '20px', marginRight: '20px', position: 'unset' }}
       autoplay={{ delay: 5000 }}
       className="reviewSlider" 
       spaceBetween={0} 
       loop={true}
       slidesPerView={1}
        >
        {slide2s.map((slide2,i) => 
            <SwiperSlide  key={i}>
                <Slider2Block data={slide2}/>
            </SwiperSlide>
        )}
      </Swiper>
    </div>
  )
}

Slider2.propTypes = {

}

export default Slider2
