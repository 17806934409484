import React from 'react'
import Slider2 from '../sliders/Slider2'
import Calendly from './Calendly'


const ThankSection3 = () => {
  return (
    <section className="section-t-3">
        <div className="container text-center">
            <div className="row">
            <div className="col-md-1" />
            <div className="col-md-10">
                <div className="row align-items-center">
                <div className="col-md-6">
                    <div className="calendarActionContent">
                    Book a free branding <br />
                    intake call{" "}
                    <img
                        src="assets/images/curved-arrow.png"
                        className="curvedArrow img-fluid"
                        alt=""
                    />
                    </div>
                    <Calendly/>
                </div>
                <div className="col-md-6 ps-md-5">
                    <Slider2/>
                </div>
                </div>
            </div>
            <div className="col-md-1" />
            </div>
        </div>
        </section>

  )
}

export default ThankSection3
