import React from 'react'
import Section1 from '../components/Section1'
import Section2 from '../components/Section2'
import Section3 from '../components/Section3'
import Section4 from '../components/Section4'
import { UserContext } from '../components/CreateContext'
import { useContext } from 'react'
const LandingPage = () => {
 
  return (
    <>

      <Section1/>
      <Section2/>
      <Section3/>
      <Section4/>
    </>
  )
}

export default LandingPage
